import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Divider,
    Card,
    Grid,
    Typography
} from '@material-ui/core';
import {
    CheckCircle
} from '@material-ui/icons';


function getValueText(value) {
    const feet = Math.floor(value / 12);
    const inches = value - (feet * 12);

    return `${feet}' ${inches}"`
}

export class SideSelector extends Component {

    state = {
        selectedSides: this.props.selectedSides ? this.props.selectedSides : ['D'],
        length: this.props.length,
        width: this.props.width,
        maxSelectable: this.props.maxSelectable ? this.props.maxSelectable : 1,
    }

    handleWallSelect = (wall) => {
        if (this.props.select) {
            this.props.select(wall);
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            length: nextProps.length,
            width: nextProps.width,
            selectedSides: nextProps.selectedSides,
            maxSelectable: nextProps.maxSelectable
        })
    }

    render() {


        const buttonOpen = (side) => {
            const index = this.state.selectedSides.indexOf(side);
            const max = this.state.maxSelectable;
            const current = this.state.selectedSides.length;

            if (index > -1) {
                return true
            } else if (max === current) {
                return false;
            } else {
                return true;
            }
        }

        const aWallButton = buttonOpen('A') && <Button
        onClick={() => this.handleWallSelect('A')}
        variant="contained"
        color="primary"
        startIcon={this.state.selectedSides.indexOf('A') > -1 && <CheckCircle style={{color: 'green'}}/>}
        >
            {this.state.selectedSides.indexOf('A') > -1 ? "Unselect Wall" : "Select Wall"}
        </Button>


        const bWallButton = buttonOpen('B') && <Button
        onClick={() => this.handleWallSelect('B')}
        variant="contained"
        color="primary"
        startIcon={this.state.selectedSides.indexOf('B') > -1 && <CheckCircle style={{color: 'green'}}/>}
        >
            {this.state.selectedSides.indexOf('B') > -1 ? "Unselect Wall" : "Select Wall"}
        </Button>

        const cWallButton = buttonOpen('C') && <Button
        onClick={() => this.handleWallSelect('C')}
        variant="contained"
        color="primary"
        startIcon={this.state.selectedSides.indexOf('C') > -1 && <CheckCircle style={{color: 'green'}}/>}
        >
            {this.state.selectedSides.indexOf('C') > -1 ? "Unselect Wall" : 'Select Wall'}
        </Button>

        const dWallButton = buttonOpen('D') && <Button
        onClick={() => this.handleWallSelect('D')}
        variant="contained"
        color="primary"
        startIcon={this.state.selectedSides.indexOf('D') > -1 && <CheckCircle style={{color: 'green'}}/>}
        >
            {this.state.selectedSides.indexOf('D') > -1 ? "Unselect Wall" : "Select Wall"}
        </Button>

        const lineWidth = ((288 - (288 - this.state.width)) / 288) * 100
        const lineHeight = ((288 - (288 - this.state.length)) / 288) * 100
        

        return (
            <Fragment>
                <Grid container spacing={0} direction="column" justify="center" align="center">
                    <Grid item xs={12} md={12}>
                        <Box flexGrow={1}>
                            <Grid container spacing={3} direction="column" justify="center" align="center">
                                
                                <Grid item>
                                    <Grid container spacing={3} direction="row" justify="space-around" alignItems="center"
                                    >
                                        <Grid item>
                                        {aWallButton}
                                        <Typography variant="h6">Length: {getValueText(this.state.length)}</Typography>
                                        </Grid>
                                        <Grid item>
                                        {dWallButton}
                                        <Typography variant="h6">Width: {getValueText(this.state.width)}</Typography>
                                        </Grid>
                                        <Grid item>
                                        {cWallButton}
                                        <Typography variant="h6">Length: {getValueText(this.state.length)}</Typography>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item>
                                    <hr
                                        style={{
                                            color:`${this.state.selectedSides.indexOf('D') > -1 ? 'green' : 'black'}`,
                                            backgroundColor: `${this.state.selectedSides.indexOf('D') > -1 ? 'green' : 'black'}`,
                                            height: 8,
                                            maxWidth: '300px',
                                            width: `${300 * (lineWidth / 100)}px`,
                                            marginBottom: `${300 * (lineHeight / 100) /2}px`,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box flexGrow={1}
                        style={{
                            marginBottom: -8,
                            marginRight: `${300 * (lineWidth / 100)}px`,
                        }}>
                            <hr
                                style={{
                                    color: `${this.state.selectedSides.indexOf('A') > -1 ? 'green' : 'black'}`,
                                    backgroundColor: `${this.state.selectedSides.indexOf('A') > -1 ? 'green' : 'black'}`,
                                    maxHeight: '300px',
                                    maxWidth: `300px`,
                                    width: `${300 * (lineHeight / 100)}px`,
                                    height: 8,
                                    transform: `rotate(90deg)`
                                }}
                            />
                        </Box>
                        <Box flexGrow={1}>
                            <hr
                                style={{
                                    color: `${this.state.selectedSides.indexOf('C') > -1 ? 'green' : 'black'}`,
                                    backgroundColor: `${this.state.selectedSides.indexOf('C') > -1 ? 'green' : 'black'}`,
                                    maxHeight: '300px',
                                    maxWidth: `300px`,
                                    width: `${300 * (lineHeight / 100)}px`,
                                    height: 8,
                                    marginLeft: `${300 * (lineWidth / 100)}px`,
                                    transform: `rotate(90deg)`
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box flexGrow={1}>
                            <Grid container spacing={3} direction="column" justify="center" align="center">
                                <Grid item>
                                    <hr
                                        style={{
                                            color: `${this.state.selectedSides.indexOf('B') > -1 ? 'green' : 'black'}`,
                                            backgroundColor: `${this.state.selectedSides.indexOf('B') > -1 ? 'green' : 'black'}`,
                                            height: 8,
                                            maxWidth: '300px',
                                            width: `${300 * (lineWidth / 100)}px`,
                                            marginTop: `${300 * (lineHeight / 100) /2}px`,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">Width: {getValueText(this.state.width)}</Typography>
                                </Grid>
                                <Grid item>
                                    {bWallButton}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

SideSelector.propTypes = {
    selectSides: PropTypes.array.isRequired,
    length: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    maxSelectable: PropTypes.number.isRequired,
    select: PropTypes.func.isRequired,
}

SideSelector.defaultProps = {
    selectedSides: ['D'],
    length: 120,
    width: 120,
    maxSelectable: 1,
    select: () => {}
}

export default SideSelector;