/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Grid,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Typography,
  makeStyles,
  Input,
  Slider,
  Button,
  IconButton
} from '@material-ui/core';
import {
  Done,
  Autorenew
} from '@material-ui/icons';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 356
  },
  desktopDrawer: {
    width: 356,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function getValueText(value) {
  const feet = Math.floor(value/12);
  const inches = value - (feet * 12);

  return `${feet}' ${inches}"`
}

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const [frameColor, setFrame] = useState('white');
  const [louvCol, setLouvCol] = useState('white');
  const [value, setValue] = useState(120);
  const [width, setWidth] = useState(120);
  const [height, setHeight] = useState(96);
  const [mount, setMount] = useState(1);
  const [louvDirect, setDirect] = useState('width');
  const [fanBeam, setFanBeam] = useState(false);
  const [trim, setTrim] = useState('None');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSliderChangeWidth = (event, newValue) => {
    setWidth(newValue);
  };

  const handleSliderChangeHeight = (event, newValue) => {
    setHeight(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleInputChangeWidth = (event) => {
    setWidth(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleInputChangeHeight = (event) => {
    setHeight(event.target.value === '' ? '' : Number(event.target.value));
  }

  const handleMount = (value) => {
    setMount(value === '' ? 1 : Number(value));
  }

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/configure"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              LouveRoof Configurator
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='Color'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Frame Color
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={3}>
                  <Grid item>
                    <Chip
                      avatar={frameColor === 'white' && <Done style={{color: 'green'}} />}
                      style={{
                        color: '#000000',
                        backgroundColor: '#ffffff'
                      }}
                      
                      label="White"
                      variant="outlined"
                      onClick={() => setFrame('white')}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                    avatar={frameColor === 'bronze' && <Done style={{color: 'green'}} />}
                      style={{
                        color: 'white',
                        backgroundColor: '#78522C'
                      }}
                      
                      label="Bronze"
                      onClick={() => setFrame('bronze')}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                    avatar={frameColor === 'stone' && <Done style={{color: 'green'}} />}
                      style={{
                        color: 'black',
                        backgroundColor: '#b7b3a8'
                      }}
                      
                      label="Stone"
                      onClick={() => setFrame('stone')}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                    avatar={frameColor === 'black' && <Done style={{color: 'green'}} />}
                      style={{
                        color: 'white',
                        backgroundColor: '#000000'
                      }}
                      
                      label="Black"
                      onClick={() => setFrame('black')}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='LouverColor'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Louver Color
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={3}>
                  <Grid item>
                    <Chip
                      avatar={louvCol === 'white' && <Done style={{color: 'green'}} />}
                      style={{
                        color: '#000000',
                        backgroundColor: '#ffffff'
                      }}
                      
                      label="White"
                      variant="outlined"
                      onClick={() => setLouvCol('white')}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                    avatar={louvCol === 'stone' && <Done style={{color: 'green'}} />}
                      style={{
                        color: 'black',
                        backgroundColor: '#b7b3a8'
                      }}
                      
                      label="Stone"
                      onClick={() => setLouvCol('stone')}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='Length'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Overall Length (IN)
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Slider
                      value={typeof value === 'number' ? value : 60}
                      onChange={handleSliderChange}
                      step={1}
                      min={60}
                      max={288}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      className={classes.input}
                      value={value}
                      margin="dense"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 60,
                        max: 288,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                </Grid>
                <br/>
              </ListItem>
              <ListItem>
              <Typography variant="h4">Currently: {getValueText(value)}</Typography>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='width'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Overall Width (IN)
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Slider
                      value={typeof width === 'number' ? width : 60}
                      onChange={handleSliderChangeWidth}
                      step={1}
                      min={60}
                      max={288}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      className={classes.input}
                      value={width}
                      margin="dense"
                      onChange={handleInputChangeWidth}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 60,
                        max: 288,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                </Grid>
                <br/>
              </ListItem>
              <ListItem>
              <Typography variant="h4">Currently: {getValueText(width)}</Typography>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='louveDirection'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Louvre Direction
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <IconButton
                    onClick={() => setDirect(!louvDirect)}
                    style={{
                      color: louvDirect ? 'green' : 'orange',
                    }}><Autorenew/></IconButton>

                  </Grid>
                  <Grid item>
                    <Button
                    onClick={() => setDirect(!louvDirect)}
                    >
                      <Typography variant="h4">Switch Direction</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='Color'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                House Mount
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={3}>
                  <Grid item>
                    <Chip
                      style={{
                        color: mount === 1 ? 'green' : 'black',
                      }}
                      variant={mount === 1 ? 'outlined' : 'default'}
                      label="One Side"
                      onClick={() => handleMount(1)}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      style={{
                        color: mount === 2 ? 'green' : 'black',
                      }}
                      variant={mount === 2 ? 'outlined' : 'default'}
                      label="Two Sides"
                      onClick={() => handleMount(2)}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      style={{
                        color: mount === 3 ? 'green' : 'black',
                      }}
                      variant={mount === 3 ? 'outlined' : 'default'}
                      label="Three Sides"
                      onClick={() => handleMount(3)}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      style={{
                        color: mount === 0 ? 'green' : 'black',
                      }}
                      variant={mount === 0 ? 'outlined' : 'default'}
                      label="Free Standing"
                      onClick={() => handleMount(0)}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        {mount !== 3 && (
          <Box p={2}>
            <List
            key='height'
            subheader={(
              <ListSubheader
              disableGutters
              disableSticky
              >Height
                </ListSubheader>
              )}
            >
              <List disablePadding>
                <ListItem
                  disableGutters
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Slider
                        value={typeof height === 'number' ? height : 96}
                        onChange={handleSliderChangeHeight}
                        step={1}
                        min={72}
                        max={180}
                        aria-labelledby="input-slider"
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        className={classes.input}
                        value={height}
                        margin="dense"
                        onChange={handleInputChangeHeight}
                        onBlur={handleBlur}
                        inputProps={{
                          step: 1,
                          min: 72,
                          max: 180,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                </ListItem>
                <ListItem>
                  <Typography variant="h4">Currently: {getValueText(height)}</Typography>
                </ListItem>
              </List>

            </List>
          </Box>
        )}
        <Divider />
        <Box p={2}>
          <List
            key='trim'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Trim and Accessories
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Checkbox
                      checked={fanBeam}
                      onChange={() => setFanBeam(!fanBeam)}
                      style={{
                        color: '#91842f'
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">Fan Beam</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Checkbox
                      checked={trim === 'Crown Moulding'}
                      onChange={() => setTrim('Crown Moulding')}
                      style={{
                        color: '#91842f'
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">Crown Moulding</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Checkbox
                      checked={trim === 'Single Step'}
                      onChange={() => setTrim('Single Step')}
                      style={{
                        color: '#91842f'
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">Single Step</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Checkbox 
                    checked={trim === 'Double Step'}
                    onChange={() => setTrim('Double Step')}
                    style={{
                      color: '#91842f'
                    }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">Double Step</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Checkbox 
                    checked={trim === 'None'}
                    onChange={() => setTrim('None')}
                    style={{
                      color: '#91842f'
                    }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">No Trim</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Box p={2} alignContent="center" alignItems="center">
          <Button
          onClick={() => console.log('configure Clicked')}
          style={{
            borderRadius: 8,
            padding: 8,
            background: 'green',
            color: 'white',
            alignSelf: 'center',
            fontSize: 18,
            marginLeft: '20%'
          }}>
            Configure
          </Button>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

//        <Box p={2}>
//<Box
//p={2}
//borderRadius="borderRadius"
//bgcolor="background.dark"
//>
//<Typography
//  variant="h6"
//  color="textPrimary"
//>
//  Need Help?
//</Typography>
//<Link
//  variant="subtitle1"
//  color="secondary"
//  component={RouterLink}
//  to="/docs"
//>
//  Check our Tutorials
//</Link>
//</Box>
//</Box>