import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Card,
  CardHeader,
  Container,
  Checkbox,
  Chip,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Typography,
  makeStyles,
  Input,
  Slider,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import {
  ToggleButton,
  ToggleButtonGroup
} from '@material-ui/lab';
import Page from 'src/components/Page';
import axios from 'axios';
import {
  Done,
  Autorenew,
  HomeWork,
  Landscape,
  Print,
  Save
} from '@material-ui/icons';
import useAuth from 'src/hooks/useAuth';
import numeral from 'numeral';
import SideSelector from './SideSelector/SideSelector';
import {useReactToPrint} from 'react-to-print';
import moment from 'moment';
import {db} from 'src/lib/firebase';
import {v4 as uuidv4} from 'uuid';

const latAndLongInfo = require('./ConfigureLibrary/zips.json');


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 356
  },
  desktopDrawer: {
    width: 356,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function getValueText(value) {
  const feet = Math.floor(value / 12);
  const inches = value - (feet * 12);

  return `${feet}' ${inches}"`
}

const ConfigureView = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [frameColor, setFrame] = useState('white');
  const [louvCol, setLouvCol] = useState('white');
  const [length, setValue] = useState(120);
  const [width, setWidth] = useState(120);
  const [height, setHeight] = useState(96);
  const [mount, setMount] = useState(1);
  const [louvDirect, setDirect] = useState('width');
  const [fanBeam, setFanBeam] = useState(false);
  const [trim, setTrim] = useState('None');
  const [exposure, setExposure] = useState('B');
  const [isLoading, setLoading] = useState(false);
  const [materials, setMaterials] = useState({});
  const [materialOpen, setMaterialOpen] = useState(false);
  const [selectedSides, setSelectedSides] = useState(['D']);
  const [motorType, setMotorType] = useState('somfy');
  const [windSensor, setWindSensor] = useState(false);
  const [rainSensor, setRainSensor] = useState(false);
  const [myLink, setMyLink] = useState(false);
  const componentRef = useRef();
  const [saveOpen, setSaveOpen] = useState(false);
  const [saveName, setSaveName] = useState('');
  const [savedConfig, setSavedConfig] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [namingError, setNamingError] = useState('');
  const [atCostWarning, setAtCostWarning] = useState(false);
  const [embedPost, setEmbedPost] = useState(false);
  const [embedDepth, setEmbedDepth] = useState(3);

  //pull in snow load
  //pull in wind load
  //add Seismic later? for footer size
  const [zipcode, setZipcode] = useState('');
  const [zipLength, setZipLength] = useState(0);

  //send API request

  const setConfigFile = () => {
    const lattyAndLong = getLatAndLong(zipcode);

    const coordinates = {
      zipcode: zipcode,
      latitude: lattyAndLong.latitude,
      longitude: lattyAndLong.longitude
    }

    const payload = {
      selectedSides: selectedSides,
      coordinates: coordinates,
      length: length,
      width: width,
      frameColor: frameColor,
      louverColor: louvCol,
      height: height,
      mount: mount,
      louvDirect: louvDirect,
      fanBeam: fanBeam,
      trim: trim,
      exposure: exposure,
      company: user.owner,
      motor: motorType,
      myLink: myLink,
      rainSensor: rainSensor,
      saveBy: user.name,
      embedPost: embedPost,
      embedDepth: embedDepth,
      //windSensor: windSensor,
    };
    setSavedConfig(payload);
  }


  //Preference for 4x8s or double 2x4s if needed

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSliderChangeWidth = (event, newValue) => {
    setWidth(newValue);
  };

  const handleTraksOpen = () => {
    window.open('https://tracking.traksos.com/app/louveroofDealer');
  }

  const handleMotorType = (newValue) => {
    setMyLink(false);
    setWindSensor(false);
    setRainSensor(false);
    setMotorType(newValue);
  }

  const handleSliderChangeHeight = (event, newValue) => {
    setHeight(newValue);
  };

  const handleDepthChange = (event, newValue) => {
    setEmbedDepth(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleInputChangeWidth = (event) => {
    setWidth(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleInputChangeHeight = (event) => {
    setHeight(event.target.value === '' ? '' : Number(event.target.value));
  }

  const handleSaveConfig = () => {
    setSaveLoading(true);
    db.collection('LouveUserConfigs').where('name', '==', saveName).where('owner', '==', user.owner).get().then((snapshot) => {
      if (snapshot.docs.length > 0) {
        alert(`Already Have Configuratin saved as ${saveName}`)
        setNamingError(`Already Have Configuratin saved as ${saveName}`)
        setSaveLoading(false);
      } else {
        let newPayload = savedConfig
        newPayload.name = saveName;
        newPayload.saveDate = moment().toDate();
        newPayload.owner = user.owner;
        newPayload.uuid = uuidv4();
        db.doc(`/LouveUserConfigs/${newPayload.uuid}`).set(newPayload).then(() => {
          setSaveLoading(false);
          setSaveOpen(false);
          setSaveSuccess(true);
        })
        .catch((err) => {
          setSaveLoading(false);
          alert(JSON.stringify(err));
        })
      }
    })
    .catch((err) => {
      setSaveLoading(false);
      alert(JSON.stringify(err));
    })
  }

  const handleMount = (value) => {
    const setSides = value === 0 ? true : false;
    if (setSides) {
      setSelectedSides([]);
    }
    setMount(value === '' ? 1 : Number(value));
  }

  const setDirection = () => {
    if (louvDirect === 'width') {
      setDirect('length');
    } else {
      setDirect('width');
    }
  }

  const handleBlur = () => {
    if (length < 0) {
      setValue(0);
    } else if (length > 100) {
      setValue(100);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  }


  function getExactIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1;
  }

  const handleZipChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    setZipcode(onlyNums)
    setZipLength(onlyNums.length);
  }

  const handleSaveName = (e) => {
    const newValue = e.target.value
    setSaveName(newValue)
  }

  const setFrameColor = (color) => {
    const currentColorAvailable = frameColor === 'bronze' || frameColor === 'stone' ? false : true;
    const thisAvailable = color === 'bronze' || color === 'stone' ? false : true;

    if (currentColorAvailable) {
      if (thisAvailable === false && trim === 'Crown Moulding') {
        setTrim('None');
        setFrame(color);
      } else {
        setFrame(color);
      }
    } else {
      setFrame(color);
    }
  }

  function getLatAndLong(zipcode) {
    const index = getExactIndex(zipcode, latAndLongInfo, 'zip');
    if (index < 0) {
      for (var i = 0; i < 20; i++) {
        const zipAsNumber = `${Number(zipcode) + i}`;
        const zipAsMinNumber = `${Number(zipcode) - i}`;
        const charLength = zipAsNumber.length;
        const charMinLength = zipAsMinNumber.length;
        const finalZip = charLength === 5 ? zipAsNumber :
          zipcode.charAt(0) === '0' ?
            `0${zipAsNumber}` : `${zipAsNumber}0`;

        const finalMinZip = charMinLength === 5 ? zipAsNumber :
          zipcode.charAt(0) === '0' ?
            `0${zipAsNumber}` : `${zipAsNumber}0`;
        const newInd = getExactIndex(finalZip, latAndLongInfo, 'zip');
        const minNewInd = getExactIndex(finalMinZip, latAndLongInfo, 'zip');
        if (newInd > -1) {
          const latitude = latAndLongInfo[newInd].lat;
          const longitude = latAndLongInfo[newInd].long;
          const coordinates = {
            latitude: latitude,
            longitude: longitude
          };
          return coordinates;
        } else if (minNewInd > -1) {
          const latitude = latAndLongInfo[minNewInd].lat;
          const longitude = latAndLongInfo[minNewInd].long;
          const coordinates = {
            latitude: latitude,
            longitude: longitude
          };
          return coordinates;
        }
      }
    } else {
      const latitude = latAndLongInfo[index].lat;
      const longitude = latAndLongInfo[index].long;
      const coordinates = {
        latitude: latitude,
        longitude: longitude
      };
      return coordinates;
    }
  }

  const handleSaveButton = () => {
    setConfigFile();
    const newVal = !saveOpen
    setSaveOpen(newVal);
  }

  const selectSide = (side) => {
    const index = selectedSides.indexOf(side);
    if (index > -1) {
      let newSides = [...selectedSides];
      newSides.splice(index, 1)
      setSelectedSides(newSides);
    } else {
      const newSides = [...selectedSides, side];
      setSelectedSides(newSides);
    }
  }


  const sendForPriceConfigure = () => {
    setLoading(true);

    const lattyAndLong = getLatAndLong(zipcode);

    const coordinates = {
      zipcode: zipcode,
      latitude: lattyAndLong.latitude,
      longitude: lattyAndLong.longitude
    }

    const payload = {
      selectedSides: selectedSides,
      coordinates: coordinates,
      length: length,
      width: width,
      frameColor: frameColor,
      louverColor: louvCol,
      height: height,
      mount: mount,
      louvDirect: louvDirect,
      fanBeam: fanBeam,
      trim: trim,
      exposure: exposure,
      company: user.owner,
      motor: motorType,
      myLink: myLink,
      rainSensor: rainSensor,
      retail: true,
      embedPost: embedPost,
      embedDepth: embedDepth,
      //windSensor: windSensor,
    };

    console.log(payload)


    axios.defaults.baseURL = 'https://us-central1-see-hi.cloudfunctions.net/api';
    axios.post('/pricingConfigure', payload)
      .then((res) => {
        console.log(res.data);
        const configuration = res.data;
        if (configuration.beams !== undefined) {
          setLoading(false);
          setMaterials(res.data);
          setMaterialOpen(true);
        } else {
          setLoading(false);
          const erro = JSON.stringify(res.data);
          alert(erro);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        alert(JSON.stringify(err));
      })
  }

  const sendForCostConfigure = () => {
    setAtCostWarning(false);
    setLoading(true);

    const lattyAndLong = getLatAndLong(zipcode);

    const coordinates = {
      zipcode: zipcode,
      latitude: lattyAndLong.latitude,
      longitude: lattyAndLong.longitude
    }

    const payload = {
      selectedSides: selectedSides,
      coordinates: coordinates,
      length: length,
      width: width,
      frameColor: frameColor,
      louverColor: louvCol,
      height: height,
      mount: mount,
      louvDirect: louvDirect,
      fanBeam: fanBeam,
      trim: trim,
      exposure: exposure,
      company: user.owner,
      motor: motorType,
      myLink: myLink,
      rainSensor: rainSensor,
      retail: false,
      embedPost: embedPost,
      embedDepth: embedDepth,
      //windSensor: windSensor,
    };


    axios.defaults.baseURL = 'https://us-central1-see-hi.cloudfunctions.net/api';
    axios.post('/pricingConfigure', payload)
      .then((res) => {
        console.log(res.data);
        const configuration = res.data;
        if (configuration.beams !== undefined) {
          setLoading(false);
          setMaterials(res.data);
          setMaterialOpen(true);
        } else {
          setLoading(false);
          const erro = JSON.stringify(res.data);
          alert(erro);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        alert(JSON.stringify(err));
      })
  }

  function showWarning() {
    if (louvDirect === 'width' && width > 150) {
      if (fanBeam) {
        setFanBeam(false);
      }
      return true;
        } else if (louvDirect === 'length' && length > 150) {
      if (fanBeam) {
        setFanBeam(false);
      }
      return true;
    } else {
      return false;
    }
  }

  function disableConfigureButton() {
    const mountMatch = mount === selectedSides.length ? true : false;
    const zipOkay = zipcode.length === 5 ? true : false;


    if (zipOkay === true && mountMatch === true) {
      return false;
    } else {
      return true;
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'LouveRoof Material List'
  })

  const content = (
    <Container
      maxWidth="lg"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={8}>
              <Box
                mt={2}
                textAlign="center"
              >
                <Link
                  component={RouterLink}
                  to="/configure"
                  variant="h5"
                  color="textPrimary"
                  underline="none"
                >
                  LouveRoof Configurator
            </Link>
              </Box>
            </Grid>
            <Grid item>
              <Button
                onClick={handleLogout}
                variant="contained"
              >Logout
          </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='Color'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Frame Color
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={3}>
                  <Grid item>
                    <Chip
                      avatar={frameColor === 'white' && <Done style={{ color: 'green' }} />}
                      style={{
                        color: '#000000',
                        backgroundColor: '#ffffff'
                      }}

                      label="White"
                      variant="outlined"
                      onClick={() => setFrameColor('white')}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      avatar={frameColor === 'bronze' && <Done style={{ color: 'green' }} />}
                      style={{
                        color: 'white',
                        backgroundColor: '#78522C'
                      }}

                      label="Bronze"
                      onClick={() => setFrameColor('bronze')}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      avatar={frameColor === 'stone' && <Done style={{ color: 'green' }} />}
                      style={{
                        color: 'black',
                        backgroundColor: '#b7b3a8'
                      }}

                      label="Stone"
                      onClick={() => setFrameColor('stone')}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      avatar={frameColor === 'black' && <Done style={{ color: 'green' }} />}
                      style={{
                        color: 'white',
                        backgroundColor: '#000000'
                      }}

                      label="Black"
                      onClick={() => setFrameColor('black')}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='LouverColor'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Louver Color
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={3}>
                  <Grid item>
                    <Chip
                      avatar={louvCol === 'white' && <Done style={{ color: 'green' }} />}
                      style={{
                        color: '#000000',
                        backgroundColor: '#ffffff'
                      }}

                      label="White"
                      variant="outlined"
                      onClick={() => setLouvCol('white')}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      avatar={louvCol === 'stone' && <Done style={{ color: 'green' }} />}
                      style={{
                        color: 'black',
                        backgroundColor: '#b7b3a8'
                      }}

                      label="Stone"
                      onClick={() => setLouvCol('stone')}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='exposure'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Build Location
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <ToggleButtonGroup
                      value={exposure}
                      exclusive
                      onChange={(event, newVal) => {
                        if (newVal !== null) {
                          setExposure(newVal)
                        }
                      }}
                      size="large"
                    >
                      <ToggleButton value="B" aria-label="B">
                        <Grid container spacing={1} direction="column" align="center" justify="center">
                          <Grid item>
                            <HomeWork />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">Neighborhood</Typography>
                          </Grid>
                        </Grid>
                      </ToggleButton>
                      <ToggleButton value="C" aria-label="C">
                        <Grid container spacing={1} direction="column" align="center" justify="center">
                          <Grid item>
                            <Landscape />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">Open Field</Typography>
                          </Grid>
                        </Grid>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='Length'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Overall Length (IN)
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Slider
                      value={typeof length === 'number' ? length : 60}
                      onChange={handleSliderChange}
                      step={1}
                      min={60}
                      max={288}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      className={classes.input}
                      value={length}
                      margin="dense"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 60,
                        max: 288,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
              </ListItem>
              <ListItem>
                <Typography variant="h4">Currently: {getValueText(length)}</Typography>
              </ListItem>
            </List>
          </List>
          <Typography variant="body1">**Length is outside of beam to outside of beam**</Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='width'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Overall Width (IN)
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Slider
                      value={typeof width === 'number' ? width : 60}
                      onChange={handleSliderChangeWidth}
                      step={1}
                      min={60}
                      max={288}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      className={classes.input}
                      value={width}
                      margin="dense"
                      onChange={handleInputChangeWidth}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 60,
                        max: 288,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
              </ListItem>
              <ListItem>
                <Typography variant="h4">Currently: {getValueText(width)}</Typography>
              </ListItem>
            </List>
          </List>
          <Typography variant="body1">**Width is outside of beam to outside of beam**</Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='louveDirection'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Louver Direction
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <IconButton
                      onClick={setDirection}
                      style={{
                        color: louvDirect === 'width' ? 'green' : 'orange',
                      }}><Autorenew /></IconButton>

                  </Grid>
                  <Grid item>
                    <Button
                      onClick={setDirection}
                    >
                      <Typography variant="h4">Switch Louver Direction</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4">Louvers Running Parrallel To: {louvDirect.toUpperCase()} {getValueText(louvDirect === 'width' ? width : length)}</Typography>
                    {showWarning() && (
                      <Typography variant="h5" style={{ marginTop: 15, color: 'red' }}>Will Need to Use Two Louver Bays</Typography>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='mount'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                House Mount
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={3}>
                  <Grid item>
                    <Chip
                      style={{
                        color: mount === 1 ? 'green' : 'black',
                      }}
                      variant={mount === 1 ? 'outlined' : 'default'}
                      label="One Side"
                      onClick={() => handleMount(1)}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      style={{
                        color: mount === 2 ? 'green' : 'black',
                      }}
                      variant={mount === 2 ? 'outlined' : 'default'}
                      label="Two Sides"
                      onClick={() => handleMount(2)}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      style={{
                        color: mount === 3 ? 'green' : 'black',
                      }}
                      variant={mount === 3 ? 'outlined' : 'default'}
                      label="Three Sides"
                      onClick={() => handleMount(3)}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      style={{
                        color: mount === 0 ? 'green' : 'black',
                      }}
                      variant={mount === 0 ? 'outlined' : 'default'}
                      label="Free Standing"
                      onClick={() => handleMount(0)}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
          {mount > 0 && (
            <Fragment>
              <SideSelector
                selectedSides={selectedSides}
                length={length}
                width={width}
                maxSelectable={mount}
                select={selectSide}
              />
            </Fragment>
          )}
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='height'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >Post Height
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Slider
                      value={typeof height === 'number' ? height : 96}
                      onChange={handleSliderChangeHeight}
                      step={1}
                      min={72}
                      max={180}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      className={classes.input}
                      value={height}
                      margin="dense"
                      onChange={handleInputChangeHeight}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 72,
                        max: 180,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
              </ListItem>
              <ListItem>
                <Typography variant="h4">Currently: {getValueText(height)}</Typography>
              </ListItem>
            </List>
            <Divider />
            <List
              key='embed'
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >Embed Post</ListSubheader>
              )}
            >
              <List disablePadding>
                <ListItem
                  disableGutters>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Checkbox
                        checked={embedPost}
                        disabled={selectedSides.length === 4}
                        onChange={() => setEmbedPost(!embedPost)}
                        color="primary"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4">Embed Posts in Footer</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" color="textSecondary">{embedPost ? 'Embeding Post in Concrete' : 'Using Steel Post Bases'}</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                {embedPost && (
                  <Fragment>
                    <ListItem
                      disableGutters>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                          <Slider
                            value={typeof embedDepth === 'number' ? embedDepth : 96}
                            onChange={handleDepthChange}
                            step={1}
                            min={1}
                            max={3}
                            aria-labelledby="input-slider"
                          />
                        </Grid>
                      </Grid>
                      <br />
                    </ListItem>
                    <ListItem>
                      <Typography variant="h4">Currently: {embedDepth} Ft.</Typography>
                    </ListItem>
                  </Fragment>
                )}
              </List>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='motor'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Motor Type
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={8}>
                    <ToggleButtonGroup
                      value={motorType}
                      exclusive
                      onChange={(event, newVal) => {
                        if (newVal !== null) {
                          handleMotorType(newVal)
                        }
                      }}
                      size="large"
                    >
                      <ToggleButton value="somfy" aria-label="somfy">
                        <Grid container spacing={1} direction="column" alignContent="center" justify="center">
                          <Grid item xs={12} md={4}>
                            <img
                            src="/static/images/Somfy_logo.svg"
                            alt="somfyLogo"
                            style={{
                              maxWidth: '90%',
                            }}/>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">Somfy</Typography>
                          </Grid>
                        </Grid>
                      </ToggleButton>
                      <ToggleButton value="gama" aria-label="gama">
                        <Grid container spacing={1} direction="column" alignContent="center" justify="center">
                          <Grid item xs={12} md={12}>
                          <img
                            src="/static/images/GAMA-Logo.png"
                            alt="gamaLogo"
                            style={{
                              maxWidth: "80%"
                            }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">gama</Typography>
                          </Grid>
                        </Grid>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        {motorType === 'somfy' && (
          <Box p={2}>
            <List
              key='trim'
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  Somfy Smart Accessories (Somfy Motor Only)
                </ListSubheader>
              )}
            >
              <List disablePadding>
                <ListItem
                  disableGutters
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Checkbox
                        checked={myLink}
                        disabled={motorType === 'gama'}
                        onChange={() => setMyLink(!myLink)}
                        color="primary"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4">Somfy MyLink</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem
                  disableGutters
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Checkbox
                        disabled={motorType==='gama'}
                        checked={rainSensor}
                        onChange={() => setRainSensor(!rainSensor)}
                        color="primary"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4">Rain Sensor</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                
              </List>
            </List>
          </Box>
        )}
        <Divider />
        <Box p={2}>
          <List
            key='trim'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >
                Trim and Accessories
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Checkbox
                      checked={showWarning() === false && fanBeam}
                      disabled={showWarning()}
                      onChange={() => setFanBeam(!fanBeam)}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">Fan Beam</Typography>
                    {showWarning() && (
                      <Typography variant="h5" color="textSecondary" style={{ fontStyle: 'italic' }}>Center Beam can be used as Fan Beam</Typography>)}
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Checkbox
                      checked={trim === 'Crown Moulding'}
                      onChange={() => setTrim('Crown Moulding')}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">Crown Moulding</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Checkbox
                      checked={trim === 'None'}
                      onChange={() => setTrim('None')}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">No Trim</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            key='height'
            subheader={(
              <ListSubheader
                disableGutters
                disableSticky
              >Zipcode
              </ListSubheader>
            )}
          >
            <List disablePadding>
              <ListItem
                disableGutters
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography variant="h4">Zipcode:</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      required
                      error={zipcode.length > 0 && zipcode.length < 5}
                      fullWidth
                      helperText={"Zipcode Must be 5 Characters Long"}
                      label="Build Location Zipcode"
                      name="zipcode"
                      onChange={handleZipChange}
                      value={zipcode}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </List>
        </Box>
        <Divider />
        <Box p={2} alignContent="center" alignItems="center">
          <Grid container spacing={3}>
            <Grid item sm />
            <Grid item sm>
              <Grid container direction="column" spacing={3} alignContent="center" justify="center">
                {disableConfigureButton() ? (
                  <Grid item>
                    {zipLength !== 5 && (
                      <Typography
                        variant="h4"
                        color="textSecondary"
                        style={{
                          textAlign: 'center',
                          marginBottom: 15
                        }}>Zipcode Needs to Be 5 Characters</Typography>
                    )}
                    {mount !== selectedSides.length && (
                      <Typography
                        variant="h4"
                        color="textSecondary"
                        style={{
                          textAlign: "center"
                        }}>Make sure you selected the proper number of walls for House Mount</Typography>
                    )}
                  </Grid>
                ) : (
                    <Divider />
                  )}
                <Grid item>
                  <Button
                    onClick={sendForPriceConfigure}
                    disabled={disableConfigureButton()}
                    color="primary"
                    fullWidth
                    variant="contained"
                    style={{
                      borderRadius: 8,
                      padding: 8,
                      alignSelf: 'center',
                      fontSize: 18,
                    }}
                  >
                    Configure
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setAtCostWarning(true)}
                    disabled={disableConfigureButton()}
                    color="primary"
                    variant="outlined"
                    style={{
                      borderRadius: 8,
                      padding: 8,
                      alignSelf: 'center',
                      fontSize: 18,
                    }}
                  >
                    Configure @ Cost
                  </Button>
                </Grid>
                <Dialog
                open={atCostWarning}
                maxWidth="sm"
                fullWidth
                onClose={() => setAtCostWarning(false)}
                >
                  <DialogTitle>
                    <Typography variant="h3">Are you Sure You Want to Configure at Cost?</Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Typography variant="h4">
                      Configuring at cost will give you the total cost of the unit for all orders today. This will give you 0% profit on the unit itself if you quote the cost to customers.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                    onClick={() => setAtCostWarning(false)}
                    variant="contained"
                    >
                      Cancel
                    </Button>
                    <Button
                    onClick={sendForCostConfigure}
                    variant="contained"
                    color="primary"
                    >
                      Configure Cost to Dealer
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
            <Grid item sm />
          </Grid>
        </Box>
      </PerfectScrollbar>
      <Dialog
        open={materialOpen}
        maxWidth="md"
        fullWidth
        onClose={() => setMaterialOpen(false)}
      >
        <DialogTitle>
          <Grid container spacing={3}>
            <Grid item sm>
              <Typography variant="h3">Build Material List</Typography>
            </Grid>
            <Grid item sm/>
            <Grid item sm>
            </Grid>
          </Grid>
          
        </DialogTitle>
        <DialogContent style={{ backgroundColor: 'grey' }} ref={componentRef}>
          <Grid container spacing={3} direction="row" justify="center" align="center">
            <Grid item xs={12} md={9}>
              <Grid container spacing={3} direction="column" justify="center" align="center">
                {materials.beams !== undefined && (
                  <Fragment>
                    <Grid item>
                      <Card style={{ padding: 15 }}>
                        <CardHeader
                          title="Frame"
                        />
                        {materials.beams.map((beam, i) => (
                          <Grid container key={i} spacing={3} direction="row" align="center" justify="space-between">
                            <Grid item>
                              <Typography variant="h4">
                                Beam{beam.count > 1 ? 's' : ''}: {beam.count}, {beam.class}10 -{beam.length} Ft {beam.color.toUpperCase()}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" color="textSecondary">
                                {numeral(beam.price).format(`$0,0.00`)}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Card>
                    </Grid>                    
                    <Grid item>
                      <Card style={{ padding: 15 }}>
                        <CardHeader
                          title="Gutters"
                        />
                        {materials.gutters.map((gutter, i) => (
                          <Grid container key={i} spacing={3} direction="row" align="center" justify="space-between">
                            <Grid item>
                              <Typography variant="h4">
                                Gutter{gutter.count > 1 ? 's' : ''}: {gutter.count}, {gutter.length} Ft {gutter.color.toUpperCase()}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" color="textSecondary">
                                {numeral(gutter.price).format(`$0,0.00`)}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card style={{ padding: 15 }}>
                        <Grid container spacing={3} direction="row" align="center" justify="space-between">
                          <Grid item>
                            <Typography variant="h4">
                              Post{materials.posts.count > 1 ? 's' : ''}: {materials.posts.count}, {materials.posts.color.toUpperCase()}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6" color="textSecondary">
                              {numeral(materials.posts.price).format(`$0,0.00`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card

                        style={{ padding: 15 }}>
                        <CardHeader
                          title="Louver Supplies" />
                        <List>
                          <ListItem
                            key="louvers"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`${materials.louvers.color.toUpperCase()} ${materials.louvers.dist}Ft Louvers`}
                              secondary={`Quantity: ${materials.louvers.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.louvers.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="linkBar"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`${materials.linkBars.color.toUpperCase()} Link Bar${materials.linkBars.count > 1 ? 's' : ''}`}
                              secondary={`Quantity: ${materials.linkBars.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.linkBars.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="pivotStrip"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`${materials.pivotStrips.color.toUpperCase()} Pivot Strip${materials.pivotStrips.count > 1 ? 's' : ''}`}
                              secondary={`Quantity: ${materials.pivotStrips.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.pivotStrips.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="retainerStrip"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`${materials.retainerStrips.color.toUpperCase()} Retainer Strip${materials.retainerStrips.count > 1 ? 's' : ''}`}
                              secondary={`Quantity: ${materials.retainerStrips.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.retainerStrips.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="endCaps"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`End Cap${materials.endCaps.count > 1 ? 's' : ''}`}
                              secondary={`Quantity: ${materials.endCaps.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.endCaps.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="endCapScrew"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`End Cap Screw${materials.endCapScrews.count > 1 ? 's' : ''}`}
                              secondary={`Quantity: ${materials.endCapScrews.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.endCapScrews.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="pushNuts"
                            alignItems="center"
                          >
                            <ListItemText
                              primary={`Push Nut${materials.pushNuts.count > 1 ? 's' : ''}`}
                              secondary={`Quantity: ${materials.pushNuts.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.pushNuts.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card style={{ padding: 15 }}>
                        <CardHeader title="Motor Assembly/Accessories" />
                        <List>
                          {materials.motor.allItems.map((item, index) => (
                            <ListItem
                              key={index}
                              alignItems='center'
                              divider={index < materials.motor.allItems.length - 1}
                            >
                              <ListItemText
                                primary={item.item}
                                secondary={`Quantity ${item.count}`}
                              />
                              <ListItemSecondaryAction>
                                <ListItemText
                                  primary={numeral(item.price).format(`$0,0.00`)}
                                  color="textSecondary" />
                              </ListItemSecondaryAction>

                            </ListItem>
                          ))}
                          {materials.rainSensor.count > 0 && (
                              <ListItem
                              key="rainSensor"
                              alignItems="center"
                              divider={true}
                              >
                                <ListItemText
                                primary='Somfy Rain Sensor'
                                />
                                <ListItemSecondaryAction>
                                  <ListItemText
                                  primary={numeral(materials.rainSensor.price).format(`$0,0.00`)}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                            {materials.myLink.price > 0 && (
                              <ListItem
                              key="myLink"
                              alignItems="center"
                              divider={true}
                              >
                                <ListItemText
                                primary='Somfy MyLink'
                                />
                                <ListItemSecondaryAction>
                                  <ListItemText
                                  primary={numeral(materials.myLink.price).format(`$0,0.00`)}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                        </List>
                      </Card>
                    </Grid>
                    {materials.crown.count > 0 && (
                      <Grid item>
                      <Card style={{padding: 15}}>
                        <CardHeader title="Trim" />
                        <List>
                          {materials.crown.count > 0 && (
                            <ListItem
                            key="crown"
                            alignItems="center"
                            divider={true}
                            >
                              <ListItemText
                              primary='Crown Moulding'
                              secondary={`Quantity ${materials.crown.count}`}
                              />
                              <ListItemSecondaryAction>
                                <ListItemText
                                primary={numeral(materials.crown.price).format(`$0,0.00`)}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          )}
                          </List>
                      </Card>
                    </Grid>
                    )}
                    {materials.rainSensor.count || materials.myLink.count && (
                      <Grid item>
                        <Card style={{padding: 15}}>
                          <CardHeader title="Accessories" />
                          <List>
                            {materials.rainSensor.count > 0 && (
                              <ListItem
                              key="rainSensor"
                                alignItems="center"
                                divider={true}
                              >
                                <ListItemText
                                  primary='Somfy Rain Sensor'
                                />
                                <ListItemSecondaryAction>
                                  <ListItemText
                                    primary={numeral(materials.rainSensor.price).format(`$0,0.00`)}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                            {materials.myLink.price > 0 && (
                              <ListItem
                                key="myLink"
                                alignItems="center"
                                divider={true}
                              >
                                <ListItemText
                                  primary='Somfy MyLink'
                                />
                                <ListItemSecondaryAction>
                                  <ListItemText
                                    primary={numeral(materials.myLink.price).format(`$0,0.00`)}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                          </List>
                        </Card>
                      </Grid>
                    )}
                    <Grid item>
                      <Card style={{ padding: 15 }}>
                        <CardHeader title="Install Supplies" />
                        <List>
                          <ListItem
                            key="Caulk"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`${materials.caulk.color.toUpperCase()} Caulk`}
                              secondary={`Quantity: ${materials.caulk.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.caulk.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="14s"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`#14 Screws ${materials.caulk.color.toUpperCase()}`}
                              secondary={`Quantity: ${materials.num14s.count} Pack${materials.num14s.count > 1 ? 's' : ''} of 100`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.num14s.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="10s"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`#10 Screws ${materials.caulk.color.toUpperCase()}`}
                              secondary={`Quantity: ${Math.ceil(materials.num10s.count)} Pack${materials.num10s.count > 1 ? 's' : ''} of 100`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.num10s.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="uChannel"
                            alignItems="center"
                            divider={true}
                          >
                            <ListItemText
                              primary={`24' U Channel`}
                              secondary={`Quantity: ${materials.uChannel.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.uChannel.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem
                            key="postBase"
                            alignItems="center"
                          >
                            <ListItemText
                              primary={`Post Base ${materials.caulk.color.toUpperCase()}`}
                              secondary={`Quantity: ${materials.postBase.count}`}
                            />
                            <ListItemSecondaryAction>
                              <ListItemText
                                primary={numeral(materials.postBase.price).format(`$0,0.00`)}
                                color="textSecondary"
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Card>
                    </Grid>
                    {materials.alerts.length > 0 && (
                      <Card style={{ padding: 15 }}>
                        <CardHeader
                          title="Alerts"
                        />
                        {materials.alerts.map((alert, i) => (
                          <Typography key={i} variant="h5" style={{ color: 'red' }}>{alert}</Typography>
                        ))}
                      </Card>
                    )}
                    <Grid item>
                      <Card style={{ padding: 15 }}>
                        <CardHeader
                          title="Price"
                        />
                        <Typography variant="h4" style={{ color: 'green' }}>
                          {numeral(materials.finalPrice).format(`$0,0.00`)}
                        </Typography>
                      </Card>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setMaterialOpen(false)}
            variant="contained"
          >Close</Button>
          <Button
            variant="contained"
            size="medium"
            onClick={handlePrint}
            startIcon={<Print />}
          >Print</Button>
          <Button
            onClick={handleSaveButton}
            variant="contained"
            color="primary"
            startIcon={<Save />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={saveOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h2">Save Configuration</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <TextField
                required
                fullWidth
                helperText={namingError.length > 0 && namingError}
                error={namingError.length > 0 && namingError}
                label="Save Name"
                name="saveName"
                onChange={handleSaveName}
                value={saveName}
                variant="outlined"
              />
            </Grid>
            {savedConfig !== {} && (
              <Grid item>
                <List>
                  <ListItem
                  key='frameColor'
                  alignItems="center"
                  divider={true}
                  >
                    <ListItemText
                    primary={'Frame Color:'}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                      primary={`${savedConfig.frameColor ? savedConfig.frameColor.toUpperCase() : ''}`}
                      color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                  key='louverColor'
                  alignItems="center"
                  divider={true}
                  >
                    <ListItemText
                    primary={'Louver Color:'}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                      primary={`${savedConfig.louverColor ? savedConfig.louverColor.toUpperCase() : ''}`}
                      color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                  key='location'
                  alignItems="center"
                  divider={true}
                  >
                    <ListItemText
                    primary={'Build Location:'}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                      primary={`${savedConfig.exposure ? savedConfig.exposure === 'B' ? 'Neighborhood' : 'Open Field' : ''}`}
                      color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                  key='zip'
                  alignItems="center"
                  divider={true}
                  >
                    <ListItemText
                    primary={'Build Zipcode:'}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                      primary={`${savedConfig.coordinates ? savedConfig.coordinates.zipcode : ''}`}
                      color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                    key="length"
                    alignItems="center"
                    divider={true}
                  >
                    <ListItemText
                      primary={`Length:`}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                        primary={`${savedConfig.length} inches`}
                        color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                    key="width"
                    alignItems="center"
                    divider={true}
                  >
                    <ListItemText
                      primary={`Width:`}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                        primary={`${savedConfig.width} inches`}
                        color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                    key="postH"
                    alignItems="center"
                    divider={true}
                  >
                    <ListItemText
                    primary={`Post Height:`}
                    />
                      <ListItemSecondaryAction>
                      <ListItemText
                        primary={`${savedConfig.height} inches`}
                        color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                    key="louvCov"
                    alignItems="center"
                    divider={true}
                  >
                    <ListItemText
                      primary={`Louver Direction:`}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                        primary={`${savedConfig.louvDirect ? savedConfig.louvDirect.toUpperCase() : ''}`}
                        color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                    key="mount"
                    alignItems="center"
                    divider={true}
                  >
                    <ListItemText
                      primary={`Mount:`}
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                        primary={`${savedConfig.mount ? savedConfig.mount > 0 ? `${savedConfig.mount} Side${savedConfig.mount > 1 ? 's' : ''}` : 'Free Standing' : 'Free Standing'}`}
                        color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                  key="motor"
                  alignItems="center"
                  divider={true}
                  >
                    <ListItemText
                    primary="Motor Type:"
                    />
                    <ListItemSecondaryAction>
                      <ListItemText
                        primary={`${savedConfig.motor ? savedConfig.motor.toUpperCase(): ''}`}
                        color="textSecondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  {savedConfig.motor === 'somfy' && (
                    <ListItem
                    key="smartAccessory1"
                    alignItems="center"
                    divider={true}
                    >
                      <ListItemText
                        primary={`Somfy MyLink:`}
                      />
                      <ListItemSecondaryAction>
                        <ListItemText
                          primary={`${savedConfig.myLink ? 'Add MyLink': 'No MyLink'}`}
                          color="textSecondary"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  {savedConfig.motor === 'somfy' && (
                    <ListItem
                    key="smartAccessory2"
                    alignItems="center"
                    divider={true}
                    >
                      <ListItemText
                        primary={`Rain Sensor:`}
                      />
                      <ListItemSecondaryAction>
                        <ListItemText
                          primary={`${savedConfig.rainSensor ? 'Has Rain Sensor': 'No Rain Sensor'}`}
                          color="textSecondary"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  <ListItem
                  key="trim"
                  alignItems="center"
                  divider={true}
                  >
                    <ListItemText
                        primary={`Trim:`}
                      />
                      <ListItemSecondaryAction>
                        <ListItemText
                          primary={`${savedConfig.trim ? `${savedConfig.trim.toUpperCase()}`: 'None'}`}
                          color="textSecondary"
                        />
                      </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                  key="fanBeam"
                  alignItems="center"
                  divider={true}
                  >
                    <ListItemText
                        primary={`Add Fan Beam:`}
                      />
                      <ListItemSecondaryAction>
                        <ListItemText
                          primary={`${savedConfig.fanBeam ? `Add Fan Beam`: 'No Added Fan Beam'}`}
                          color="textSecondary"
                        />
                      </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveButton}
            variant="contained"
          >
            Close
          </Button>
          <Button
            onClick={handleSaveConfig}
            variant="contained"
            disabled={saveName.length < 1 || saveLoading}
            color="primary"
            startIcon={saveLoading ? <CircularProgress /> : <Save />}
            >
              {saveLoading ? 'Saving...' : 'Save Configuration'}
            </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isLoading}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={3}
            direction="column"
            justify="center"
            alignItems="center">
            <Grid item>
              <Typography variant="h2">Configuring...</Typography>
            </Grid>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
      open={saveSuccess}
      onClose= { () => setSaveSuccess(false)}
      maxWidth='md'
      fullWidth
      >
        <DialogContent>
          <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12}>
              <Typography variant="h3">Configuration Saved! Find saved files in Traks Account</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
                <Grid item sm>
                  <img
                    src="/static/images/technologies/LouveRoofItem.png"
                    alt="gamaLogo"
                    style={{
                      maxWidth: "80%"
                    }}
                  />
                </Grid>
                <Grid item sm>
                  <img
                    src="/static/images/technologies/LouveRoofItem2.png"
                    alt="gamaLogo"
                    style={{
                      maxWidth: "80%"
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
          onClick={() => setSaveSuccess(false)}
          variant="contained"
          >Close</Button>
          <Button
          onClick={handleTraksOpen}
          variant="contained"
          color="primary"
          >Open Traks</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );

  return (
    <>
      <Page
        title="LouveRoof Configure"
      >
        {content}
      </Page>
    </>
  );
};

ConfigureView.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default ConfigureView;

//<ListItem
//disableGutters
//>
//  <Grid container spacing={2} alignItems="center">
//    <Grid item>
//      <Checkbox
//        checked={trim === 'Single Step'}
//        onChange={() => setTrim('Single Step')}
//        style={{
//          color: '#91842f'
//        }}
//      />
//    </Grid>
//    <Grid item>
//      <Typography variant="h4">Single Step</Typography>
//    </Grid>
//  </Grid>
//</ListItem>
//<ListItem
//  disableGutters
//>
//  <Grid container spacing={2} alignItems="center">
//    <Grid item>
//      <Checkbox
//        checked={trim === 'Double Step'}
//        onChange={() => setTrim('Double Step')}
//        style={{
//          color: '#91842f'
//        }}
//      />
//    </Grid>
//    <Grid item>
//      <Typography variant="h4">Double Step</Typography>
//    </Grid>
//  </Grid>
//</ListItem>