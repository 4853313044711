import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {},
  overview: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    }
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48
  },
  details: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }
}));

const Subscription = ({ className, ...rest }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [subscription, setSubscription] = useState(null);

  const { user } = useAuth();
  const source = user.tier === 'Free' ? '/static/images/products/product_free.svg' : 
  user.tier === 'Contractor' ? '/static/images/products/product_standard.svg' : 
  user.tier === 'Small Business' ? '/static/images/products/product_premium--outlined.svg' : '/static/images/products/product_extended.svg'

  const getSubscription = useCallback(async () => {
    try {
      const response = await axios.get('/api/account/subscription');

      function returnPlanNormal(plan) {
        if (plan === 'Contractor') {
            return 19;
        } else if (plan === 'Small Business') {
            return 79;
        } else if (plan === 'Business Optimize') {
            return 149;
        } else return 0;
      }

      function returnUsers(plan) {
        if (plan === 'Contractor') {
          return 1;
      } else if (plan === 'Small Business') {
          return 5;
      } else if (plan === 'Business Optimize') {
          return 20;
      } else return 1;
      }
      
      function returnAnalytics(plan) {
        if (plan !== 'Free') {
          return true
        } else {
          return false
        }
      }

      function maxStorage(plan) {
        if (plan === 'Contractor') {
          return 5;
      } else if (plan === 'Small Business') {
          return 10;
      } else if (plan === 'Business Optimize') {
          return 'Unlimited';
      } else return 2;
      }

      if (isMountedRef.current) {

        let plan = {
          name: user.tier,
          price: returnPlanNormal(user.tier),
          currency: '$',
          users: returnUsers(user.tier),
          hasAnalytics: returnAnalytics(user.tier),
          storage: maxStorage(user.tier),
          hasSalesTracking: user.tier !== 'Free' && user.tier !== 'Contractor' ? true : false,
          taskAssignment: user.tier !== 'Free' && user.tier !== 'Contractor' ? true : false,
        }
        setSubscription(plan);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getSubscription();
  }, [getSubscription]);

  if (!subscription) {
    return null;
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Manage your subscription" />
      <Divider />
      <CardContent>
        <Paper variant="outlined">
          <Box className={classes.overview}>
            <div>
              <Typography
                display="inline"
                variant="h4"
                color="textPrimary"
              >
                {subscription.currency}
                {subscription.price}
              </Typography>
              <Typography
                display="inline"
                variant="subtitle1"
              >
                /mo
              </Typography>
            </div>
            <Box
              display="flex"
              alignItems="center"
            >
              <img
                alt="Product"
                className={classes.productImage}
                src={source}
              />
              <Typography
                variant="overline"
                color="textSecondary"
              >
                {subscription.name}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box className={classes.details}>
            <div>
              <Typography
                variant="body2"
                color="textPrimary"
              >
                {`${subscription.users} Max Users`}
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
              >
                {`${subscription.storage} GB of Storage`}
              </Typography>
            </div>
            <div>
              {subscription.taskAssignment && (
                <Typography
                variant="body2"
                color="textPrimary"
                >
                  Task Assignment/Employee Scheduling
                </Typography>
              )}
              {subscription.hasSalesTracking && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  Sales Team Tracking/Analytics
                </Typography>
              )}
            </div>
            <div>
              {subscription.hasAnalytics && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  Pro Analytics Dashboard
                </Typography>
              )}
            </div>
          </Box>
        </Paper>
        <Box
          mt={2}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            color="secondary"
            component="a"
            href="/pricing"
            variant="contained"
          >
            Upgrade Plan
          </Button>
        </Box>
        <Box mt={2}>
          <Typography
            variant="body2"
            color="textSecondary"
          >
            The refunds don&apos;t work once you have the subscription, but you can
            always
            {' '}
            <Link
              color="secondary"
              component={RouterLink}
              to="#"
            >
              Cancel your subscription
            </Link>
            .
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Subscription.propTypes = {
  className: PropTypes.string
};

export default Subscription;
