import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import wait from 'src/utils/wait';
import { db } from 'src/lib/firebase';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles(() => ({
  root: {}
}));

const GeneralSettings = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [thisUser, setUser] = useState({})
  const isMountedRef = useIsMountedRef();

  const { updateUser } = useAuth();

  const syncUser = (user) => {
    setUser(user);
  }

  const runOpen = useCallback(async () => {
    const passedUser = user;
    if (isMountedRef.current) {
      syncUser(passedUser);
    }
  }, [isMountedRef]);

  useEffect(() => {
    runOpen();
  }, [runOpen]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: thisUser.firstName || '',
        lastName: thisUser.lastName || '',
        city: thisUser.city || '',
        streetAddress: thisUser.streetAddress || '',
        phone: thisUser.phone || '',
        state: thisUser.state || '',
        zipcode: thisUser.zipcode || '',
        jobRole: thisUser.jobRole || '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required('First Name Required'),
        city: Yup.string().max(255),
        phone: Yup.string(),
        state: Yup.string()
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          const userUuid = user.id;

          const first = values.firstName.trim()
          const last = values.lastName.trim()

          const userName = `${first} ${last}`;

          const update = {
            userFirstName: first,
            userLastName: last,
            username: userName,
            city: values.city,
            phone: values.phone,
            state: values.state,
            streetAddress: values.streetAddress,
            zipcode: values.zipcode,
            jobRole: values.jobRole
          }

          db.doc(`/MUser/${userUuid}`).update(update);

          let newUser = update;
          newUser.email = user.email;
          newUser.firstName = first;
          newUser.lastName = last;
          newUser.name = userName;

          setUser(newUser);
          updateUser(userUuid);
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('Profile updated', {
            variant: 'success'
          });
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card
            className={clsx(classes.root, className)}
            {...rest}
          >
            <CardHeader title="Profile" />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label="First Name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label="Last Name"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Typography variant="body2">Email:</Typography>
                  <Typography variant="h5">{thisUser.email}</Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    label="Phone Number"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.streetAddress && errors.streetAddress)}
                    fullWidth
                    helperText={touched.streetAddress && errors.streetAddress}
                    label="Street Address"
                    name="streetAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.streetAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    label="City"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.state && errors.state)}
                    fullWidth
                    helperText={touched.state && errors.state}
                    label="State/Region"
                    name="state"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.state}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.zipcode && errors.zipcode)}
                    fullWidth
                    helperText={touched.zipcode && errors.zipcode}
                    label="Zipcode"
                    name="zipcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.zipcode}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.jobRole && errors.jobRole)}
                    fullWidth
                    helperText={touched.jobRole && errors.jobRole}
                    label="Job Role"
                    name="jobRole"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.jobRole}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
            </CardContent>
            <Divider />
            <Box
              p={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                color="secondary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save Changes
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default GeneralSettings;
